<template>
  <div class="c-promotion-detail">
    <div class="buttons flex flex-row justify-around md:justify-between">
      <button type="button" class="twn-button text-xs" @click="goBack">
        Retour
      </button>
      <div v-if="!isReadOnly">
        <button
          type="button"
          class="twn-button text-xs danger mr-4"
          @click="showDeleteModal()"
          v-if="itemID"
          :disabled="hasPendingStoreRequest"
        >
          Supprimer la promotion
        </button>
        <button
          type="button"
          class="twn-button text-xs"
          @click="save"
          :disabled="hasPendingStoreRequest"
        >
          Enregistrer
        </button>
      </div>
    </div>

    <div class="my-10">
      <div class="flex">
        <div v-if="!itemID" class="flex-grow uppercase font-principal-bold text-sm">
          Ajouter une promotion
        </div>
        <div v-else class="flex-grow uppercase font-principal-bold text-sm">
          Éditer la promotion
        </div>
        <div>
            <span>Suspendue</span>
            <Toggle :disabled="isReadOnly" :checked="!promotion.disabled" :onInput="() => { promotion.disabled = !promotion.disabled }" class="mx-4"></Toggle>
            <span>Active</span>
        </div>
      </div>

      <!-- Group common fields -->
      <div class="mt-8 mb-4">
        <!-- Identifier -->
        <div class="form-group inline-block w-1/3 pr-2">
          <label for="game-name" class="uppercase font-principal-medium text-sm">Identifiant</label>
          <b-form-input :disabled="isReadOnly" class="w-full" v-model="promotion.identifier" type="text" autofocus/>
        </div>

        <!-- Name -->
        <div class="form-group inline-block w-1/3 pl-2">
          <label for="game-name" class="uppercase font-principal text-sm"
            >Nom</label
          >
          <b-form-input :disabled="isReadOnly" class="w-full" v-model="promotion.name" type="text" autofocus/>
        </div>

        <div class="form-group inline-block w-1/3 pl-2">
          <label>Parcours associé</label>
          <v-select
            label="name"
            placeholder="Parcours"
            v-model="promotion.course"
            :options="courseList"
            :filterable="true"
            :reduce="course => course.id"
            :disabled="isReadOnly"
          />
        </div>

        <!-- Start date -->
        <div class="form-group inline-block w-1/3 pr-2">
          <label>Date d'ouverture</label>
          <b-form-datepicker
          :disabled="isReadOnly"
          class="w-full"
          v-model="promotion.startDate"
          :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit', weekday: 'long' }"
          ></b-form-datepicker>
        </div>

        <!-- End date -->
        <div class="form-group inline-block w-1/3 px-2">
          <label>Date de fermeture</label>
          <b-form-datepicker
          :disabled="isReadOnly"
          class="w-full"
          v-model="promotion.endDate"
          :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit', weekday: 'long' }"
          :min="promotion.startDate"
          ></b-form-datepicker>
        </div>

        <!-- Customer -->
        <!-- <div class="align-top form-group inline-block w-1/3 pl-2" v-if="!isUserManager">
          <label for="group-customer">Client</label>

          <div class="flex">
            <v-select
            :disabled="isReadOnly"
            id="group-customer"
            class="twn-select flex-grow"
            placeholder="Rechercher un client..."
            :getOptionLabel="getCustomerLabel"
            :options="customerList"
            :reduce="customer => customer.id"
            v-model="promotion.customer_id" />

            <router-link
              v-if="promotion.customer_id"
              class="twn-button text-xs small ml-2 flex-shrink-0"
              :to="{
                name: 'customer-edit',
                params: { itemID: promotion.customer_id }
              }"
              target="_blank"
            >
              Voir le client
           </router-link>
          </div>
        </div> -->

        <div class="form-group inline-block" v-if="typeList.length > 2">
          <label>Type</label>
          <v-select
            :disabled="isReadOnly"
            class="flex-1 mr-auto"
            label="name"
            placeholder="Type de promotion"
            v-model="promotion.type"
            :options="typeList"
            :reduce="type => type.id"
            :clearable="false"
          />
        </div>

        <div class="mt-8" v-if="promotionType">
          <div class="tabs-triggers flex rounded border border-gray-light">
            <button
            v-if="hasSequencesSchedule"
            :class="{
              'selected': (currentTab == 'sequences')
            }"
            type="button"
            @click="currentTab = 'sequences'"
            >
              <span v-if="promotionType.slug == 'collective_promotion'">Dates de disponibilité des séquences</span>
              <span v-else-if="promotionType.slug == 'individual_promotion'">Dates limites de planification des séquences</span>
            </button>

            <button
            :class="{
              'selected': (currentTab == 'users')
            }"
            type="button"
            @click="currentTab = 'users'"
            >
              Utilisateurs
            </button>

            <button
            v-if="hasInternSchedule"
            :class="{
              'selected': (currentTab == 'calendar')
            }"
            type="button"
            @click="currentTab = 'calendar'"
            >
              <span v-if="promotionType.slug == 'collective_promotion'">Rattrapages</span>
              <span v-else-if="promotionType.slug == 'individual_promotion'">Calendrier des stagiaires</span>
            </button>
          </div>

          <PromotionSequenceForm
            v-show="currentTab == 'sequences'"
            :course="course"
            :promotion="promotion"
            :promotion-type="promotionType"
            @set-sequence-start="onSetSequenceStartDate"
            @set-sequence-end="onSetSequenceEndDate"
          />
          <PromotionInternsForm
            v-show="currentTab == 'users'"
            :promotion="promotion"
            @add-intern="onAddIntern"
            @remove-intern="onRemoveIntern"
            :is-read-only="isReadOnly"
          />
          <PromotionInternSequenceForm
            v-show="currentTab == 'calendar'"
            :course="course"
            :promotion="promotion"
            :promotion-type="promotionType"
            @add-user-sequence="onAddInternSequence"
            @remove-user-sequence="onRemoveInternSequence"
          />
        </div>
      </div>

      <GroupDashboard v-if="promotion && promotion.id" :group="promotion" />
    </div>

    <!-- Modals -->
    <b-modal
      ref="delete-modal-detail-promotion"
      class="bootstrap"
      centered
      hide-footer
      id="delete-modal-detail-promotion"
      hide-header
    >
      <div class="d-block text-center my-6 uppercase font-semibold">
        <h3>Confirmer la suppression de "{{promotion.name}}"</h3>
      </div>
      <div class="flex flex-row justify-evenly items-center">
        <button type="button" class="mt-4 twn-button" @click="$bvModal.hide('delete-modal-detail-promotion')" >
          Retour
        </button>
        <button type="button" class="mt-4 twn-button danger" @click="onConfirmDelete">Supprimer</button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapState } from 'vuex'

import dispatchStoreRequest from "@/mixins/dispatchStoreRequest"

import Toggle from '@/components/Toggle'
import PromotionSequenceForm from "./PromotionSequenceForm"
import PromotionInternsForm from "./PromotionInternsForm"
import PromotionInternSequenceForm from "./PromotionInternSequenceForm"
import GroupDashboard from '@/components/dashboard/Group'

export default {
  props: [ 'itemID' ],
  mixins: [ dispatchStoreRequest ],
  components: {
    Toggle,
    PromotionSequenceForm,
    PromotionInternsForm,
    PromotionInternSequenceForm,
    GroupDashboard
  },
  data: () => ({
    // UI State
    currentTab: 'users',
    // Local promotion data
    promotion: {
      id: null,
      identifier: '',
      name: '',
      startDate: new Date(),
      endDate: null,
      course: null,
      customer_id: null,
      disabled: false,
      type: null,
      sequences: [],
      users: [],
    },
    course: null,
  }),
  computed: {
    ...mapState({
      courseList: state => state.Course.list,
      typeList: state => state.Promotion.typeList,
      customerList: state => state.Customers.list,
      userInfo: state => state.Auth.userInfo
    }),
    isSuperAdmin() {
      return (this.userInfo && this.userInfo.role == 'superadmin')
    },
    isUserManager() {
      return (this.userInfo && this.userInfo.role == 'customer_manager')
    },
    isReadOnly() {
      if (this.promotionType && this.promotionType.slug === 'gescof') {
        return true
      }

      if (this.isUserManager) {
        return (this.promotion.id && this.managedCustomer.id !== this.promotion.customer_id)
      }

      return !this.isSuperAdmin
    },
    managedCustomer() {
      if (!this.isUserManager) {
        return null
      }

      return (this.userInfo && this.userInfo.managed_groups?.[0]?.customer) || null
    },
    promotionType() {
      if (!this.typeList || !this.promotion || !this.promotion.type)
        return null

      return this.typeList.find((type) => (type.id == this.promotion.type))
    },
    hasSequencesSchedule() {
      if (!this.promotionType)
        return false

      const validTypes = ['collective_promotion', 'individual_promotion']

      return (validTypes.indexOf(this.promotionType) > -1)
    },
    hasInternSchedule() {
      if (!this.promotionType)
        return false

      const validTypes = ['collective_promotion', 'individual_promotion']

      return (validTypes.indexOf(this.promotionType) > -1)
    }
  },
  watch: {
    itemID: {
      async handler(itemID) {
        if (itemID) {
          // todo: handle invalid uuid response
          await this.dispatchStoreRequest('Promotion/getByID', itemID, true)

          // Convert remote data to local one
          const remoteData = this.$store.state.Promotion.items[itemID]

          this.promotion.id = itemID

          // todo: handle error
          if (!remoteData)
            return

          this.promotion.identifier = remoteData.identifier
          this.promotion.name = remoteData.name
          this.promotion.startDate = remoteData.start_date
          this.promotion.endDate = remoteData.end_date
          this.promotion.course = remoteData.course_id
          this.promotion.customer_id = remoteData.customer_id
          this.promotion.disabled = remoteData.disabled
          this.promotion.scenarios = null //Set scenario var for log data
          this.promotion.type = remoteData.promotion_type_id
          this.promotion.sequences = remoteData.sequences.map((sequence) => {
            return {
              id: sequence.sequence_id,
              startDate: new Date(sequence.start_date),
              endDate: new Date(sequence.end_date),
              userID: sequence.user_id,
            }
          })
          this.promotion.users = remoteData.users.map((userData) => {
            return userData.user
          })
        } else {
          // Clear local data
          this.promotion.id = null
          this.promotion.identifier = ''
          this.promotion.name = ''
          this.promotion.startDate = new Date()
          this.promotion.endDate = null
          this.promotion.course = null
          this.promotion.customer_id = null
          this.promotion.disabled = false
          this.promotion.sequences = []
          this.promotion.users = []
          this.promotion.scenarios = null

          // Set default values if possible
          if (!this.promotion.type && this.typeList && this.typeList.length > 0 && this.typeList[0].id) {
            this.promotion.type = this.typeList[0].id
          } else {
            this.promotion.type = null
          }
        }
      },
      immediate: true,
    },
    'promotion.startDate': {
      handler(startDate) {
        // Automatically set end date to start date + 1 year
        if (startDate && !this.promotion.endDate) {
          let nextYear = new Date(startDate)
          nextYear.setFullYear(nextYear.getFullYear() + 1)
          
          this.promotion.endDate = nextYear
        }
      },
      immediate: true,
    },
    typeList(list) {
      // Set default values
      if (!this.promotion.type && list && list.length > 0 && list[0].id) {
        this.promotion.type = list[0].id
      }
    },
    customerList(list) {
      // Set default values
      if (!this.promotion.customer_id && list && list.length > 0 && list[0].id) {
        this.promotion.customer_id = list[0].id
      }
    },
    'promotion.type': {
      handler(type) {
        // Set default values
        if (!type && this.typeList && this.typeList.length > 0 && this.typeList[0].id) {
          this.promotion.type = this.typeList[0].id
        }
      }
    },
  },
  async mounted() {
    // Load users, course and promotion type list
    await this.dispatchStoreRequest('Course/getList')
    await this.dispatchStoreRequest('Promotion/getTypeList')
    await this.dispatchStoreRequest('Customers/getList')
  },
  methods: {
    getCustomerLabel(customer) {
      if (!customer)
        return 'Utilisateur supprimé'

      return [customer.identifier, customer.name].filter(str => str || false).join(' ')
    },
    goBack() {
      this.$router.push({ name: 'promotions-list' })
    },
    showDeleteModal() {
      this.$refs['delete-modal-detail-promotion'].show()
    },
    onConfirmDelete(){
      this.$store.dispatch('Promotion/delete', this.itemID)
      this.$bvModal.hide('delete-modal-detail-promotion')
      this.goBack()
    },
    onSetSequenceStartDate({ sequenceID, startDate }) {
      // Try to find and update the sequence data
      for (var i = 0; i < this.promotion.sequences.length; i++) {
        if (this.promotion.sequences[i].userID == null && this.promotion.sequences[i].id == sequenceID) {
          this.promotion.sequences[i].startDate = startDate
          return
        }
      }
      
      // If no data exist, create a default one
      this.promotion.sequences.push({
        id: sequenceID,
        startDate,
        endDate: null,
        userID: null,
      })
    },
    onSetSequenceEndDate({ sequenceID, endDate }) {
      // Try to find and update the sequence data
      for (var i = 0; i < this.promotion.sequences.length; i++) {
        if (this.promotion.sequences[i].userID == null && this.promotion.sequences[i].id == sequenceID) {
          this.promotion.sequences[i].endDate = endDate
          return
        }
      }
      
      // If no data exist, create a default one
      this.promotion.sequences.push({
        id: sequenceID,
        startDate: null,
        endDate,
        userID: null,
      })
    },
    async onAddIntern(intern) {
      const user = await this.dispatchStoreRequest('Promotion/getPromotionUserDetailsByID', intern.id)

      if (user && user.id) {
        this.promotion.users.unshift(user)
      }
    },
    onRemoveIntern(intern) {
      for (var i = 0; i < this.promotion.users.length; i++) {
        if (this.promotion.users[i].id == intern.id) {
          this.promotion.users.splice(i, 1)
          break
        }
      }
    },
    onAddInternSequence(internSequence) {
      this.promotion.sequences.push({
        id: internSequence.id,
        startDate: internSequence.startDate,
        endDate: internSequence.endDate,
        userID: internSequence.userID,
      })
    },
    onRemoveInternSequence(internSequence) {
      for (var i = 0; i < this.promotion.sequences.length; i++) {
        if (this.promotion.sequences[i].id == internSequence.id && this.promotion.sequences[i].userID == internSequence.userID) {
          this.promotion.sequences.splice(i, 1)
          break
        }
      }
    },
    async save() {
      const response = await this.dispatchStoreRequest('Promotion/save', this.promotion)

      if (response.id) {
        this.$router.push({
          name: 'promotions-edit',
          params: {
            itemID: response.id,
          }
        })
      }

      this.$bvToast.toast('Vos modifications ont bien été enregistrés !', { title: `Succès !` })
    },
  },
}
</script>

<style lang="scss" scoped>;
  .tabs-triggers {
    button {
      @apply flex-1 p-2 font-principal-medium;

      &.selected {
        @apply text-white bg-red;
      }
    }
  }
</style>
<template>
  <div>
    <List
      v-if="!isLoading"
      listing="group"
      :fields="fields"
      order-by="end_date: desc, start_date: asc"
      search-text="Rechercher une promotion"
      :pageSize="pageSize"
      edit-route-name="promotions-edit"
    >
      <template v-slot:buttons>
        <div v-if="!isReadOnly" class="md:flex flex-row justify-end hidden md:w-1/3">
          <button
            type="button"
            class="twn-button whitespace-no-wrap overflow-hidden text-xs mr-2"
            @click="onAddPromotionClick"
          >
            Ajouter une nouvelle promotion
          </button>
        </div>
      </template>
    </List>
  </div>
</template>

<script>
import List from "@/components/List";
import { mapActions, mapState } from "vuex";

export default {
  components: {
    List,
  },
  props: {
    pageSize:{
      type: Number,
      default: 30,
      required: false
    }
  },
  data: () => ({
    isLoading: false,
  }),
  computed: {
    ...mapState('Auth', [ 'userInfo' ]),
    isSuperAdmin() {
      return (this.userInfo && this.userInfo.role == 'superadmin')
    },
    isUserAdmin() {
      return (this.userInfo && (this.userInfo.role == 'superadmin' || this.userInfo.role == 'customer_manager'))
    },
    isReadOnly() {
      return !this.isSuperAdmin && !this.isUserAdmin
    },
    fields() {
      const fields = [
        {
          key: "identifier",
          label: "Identifiant",
          sortable: true,
        },    
        {
          key: "name",
          label: "Nom",
          sortable: true,
        },
        {
          key: 'start_date',
          db: `start_date
          end_date`,
          label: "Dates",
          sortable: true,
          formatter: (_value, _key, item) => {
            const start = (item.start_date ? item.start_date.split('-').reverse().join('/') : '')
            const end = (item.end_date ? item.end_date.split('-').reverse().join('/') : '')

            return start + ' - ' + end
          }
        },
        {
          key: 'type',
          db: `type {
            name
          }`,
          nested: 'name',
          label: 'Type',
          sortable: true,
        },
        {
          key: 'disabled',
          label: 'Statut',
          sortable: true,
          formatter: (value, _key, item) => {
            if (value)
              return 'Suspendue'

            const now = new Date()
            const start = new Date(item.start_date)
            const end = new Date(item.end_date)

            return (now < start ? 'À venir' : (now > end ? 'Passé' : 'En cours'))
          }
        },
        {
          key: 'users_aggregate',
          label: "Stagiaires",
          sortable: true,
          db: `users_aggregate(where: {
            user: {
              role: { _eq: "user" },
            }
          }) {
            aggregate {
              count
            }
          }`,
          nested: 'count'
        },
        {
          label: 'Actifs',
          sortable: true,
          key: 'actives',
          nested: 'count',
          db: `actives: users_aggregate(where: {user: {
            role: { _eq: "user" },
            cognito_confirmed: {_eq: true}}}) {
            aggregate {
              count
            }
          }`
        },
        {
          label: 'Terminés',
          sortable: true,
          sortByFormatted: true,
          key: 'users',
          db: `
            course {
              modules {
                scenarios_aggregate {
                  aggregate {
                    count
                  }
                }
                sequences {
                  scenarios_aggregate {
                    aggregate {
                      count
                    }
                  }
                }
              }
            }
            users {
              user {
                user_logs_aggregate(
                  where: {
                    type: {
                      slug: {
                        _eq: "scenario_completed"
                      }
                    }
                  },
                  distinct_on: data_key
                ) {
                  aggregate {
                    count
                  }
                }
              }
            }
          `,
          formatter: (_value, _key, group) => {
            // Count available scenario in the group course
            const availableScenarioCount = group.course.modules.reduce((sum, module) => {
              sum += module.scenarios_aggregate.aggregate.count

              module.sequences.forEach((sequence) => {
                sum += sequence.scenarios_aggregate.aggregate.count
              })

              return sum
            }, 0)

            // Count user with more scenario done than the available number
            return group.users.reduce((sum, userData) => {
              if (userData.user.user_logs_aggregate.aggregate.count >= availableScenarioCount) {
                sum += 1
              }
              return sum
            }, 0)
          },
        },
      ]

      // if (this.isSuperAdmin) {
      //   fields.splice(2, 0,
      //     {
      //       key: 'customer',
      //       label: "Client",
      //       sortable: true,
      //       db: `customer {
      //         identifier
      //         name
      //       }`,
      //       formatter: (value) => {
      //         if (!value)
      //           return 'Aucun'

      //         return [value.identifier, value.name].filter(str => str || false).join(' - ') || '~ Client supprimé ~'
      //       }
      //     }
      //   )
      // }

      return fields
    }
  },
  methods: {
    ...mapActions("EditItem", ["clearCurrentID"]),
    onAddPromotionClick() {
      this.clearCurrentID();
      this.$router.push({ name: "promotions-create" });
    },
  },
};
</script>
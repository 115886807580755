const oneHour = (1 * 60 * 60 * 1000)
const fiveMinutes = (5 * 60 * 1000)

export default {
	mailingLogs(user) {
		// Create a map of type => log from the user mailer logs
		return user.logs.reduce((dict, log) => {
			if (log.type.slug == 'mail' && log.data.type) {
				dict[log.data.type] = log
			}

			return dict
		}, {})
	},
	// progress(user, data) {
	// 	// Get sequence done in this course
	// 	const courseSequenceDoneById = (user.logByTypeSlug['sequence_end'] || []).reduce((dict, log) => {
	// 		if (data.course.sequenceById[log.data_key]) {
	// 			dict[log.data_key] = true
	// 		}
	// 		return dict
	// 	}, {})

	// 	return Object.keys(courseSequenceDoneById).length / (data.course.modules[0].sequences.length || 1)
	// },
	progressionData(user, data) {
		// Init computed data
		let computedProgressionData = {
			hasStarted: false,
			progress: 0,
			doneTimestamp: null,
		}

		// Get scenario done by user and the date of the first time it was done
		const userScenarioFirstDoneDateById = {}

		user.logs.forEach((log) => {
			const logTime = (new Date(log.created_at)).getTime()

			if (!computedProgressionData.hasStarted && log.type.slug == 'activity_completed') {
				computedProgressionData.hasStarted = true
			} else if (log.type.slug == 'scenario_completed' && !userScenarioFirstDoneDateById[log.data_key]) {
				computedProgressionData.hasStarted = true
				
				userScenarioFirstDoneDateById[log.data_key] = logTime
			}
		})
		
		// Count scenario done and available in the user courses
		const userCourses = user.groups.reduce((list, groupData) => {
			list.push(data.coursesById[groupData.group.course_id])
			return list
		}, [])

		const userCoursesProgress = userCourses.map((course) => {
			let scenarioDone = 0
			let scenarioAvailable = 0
			let lastScenarioTimestamp = null

			course.modules.forEach((module) => {
				// Count scenario done in this module
				module.scenarios.forEach((scenario) => {
					if (userScenarioFirstDoneDateById[scenario.id]) {
						scenarioDone += 1
						lastScenarioTimestamp = userScenarioFirstDoneDateById[scenario.id]
					}
				})

				// Count scenario available in this module
				scenarioAvailable += module.scenarios.length

				// Count scenario available and done in the module sequences
				module.sequences.forEach((sequence) => {
					// Count scenario done in this sequence
					sequence.scenarios.forEach((scenario) => {
						if (userScenarioFirstDoneDateById[scenario.id]) {
							scenarioDone += 1
							lastScenarioTimestamp = userScenarioFirstDoneDateById[scenario.id]
						}
					})

					// Count scenario available in this sequence
					scenarioAvailable += sequence.scenarios.length
				})
			})

			return {
				progress: (scenarioDone / (scenarioAvailable || 1)),
				lastScenarioTimestamp,
			}
		})


		if (userCoursesProgress.length > 0) {
			const progressSum = userCoursesProgress.reduce((sum, courseProgress) => {
				sum += courseProgress.progress
				return sum
			}, 0)

			computedProgressionData.progress = (progressSum / userCoursesProgress.length)
		}

		if (computedProgressionData.progress >= 1) {
			computedProgressionData.doneTimestamp = userCoursesProgress.reduce((lastTimestamp, courseProgress) => {
				if (!lastTimestamp || courseProgress.lastScenarioTimestamp > lastTimestamp) {
					lastTimestamp = courseProgress.lastScenarioTimestamp
				}
				
				return lastTimestamp
			}, null)
		}

		return computedProgressionData
	},
	activeTimeData(user) {
		// Init computed data
		let computedTimeData = {
			times: [],
			sum: 0,
			max: 0,
			average: 0,
			lastSessionTimestamp: 0
		}

		const sessionStops = {}

		// Get all event date times
		const times = user.logs.reduce((times, log) => {
			// Exclude "passive" logs
			if (['mail'].indexOf(log.type.slug) > -1) {
				return times
			}

			// Add log creation time
			const logTime = (new Date(log.created_at)).getTime()
			times.push(logTime)

			// Register all log time who should always trigger a new session
			if (['login', 'logout'].indexOf(log.type.slug) > -1) {
				sessionStops[logTime] = true
			}

			return times
		}, []).sort()

		// Get all data by spliting user logs in sessions
		let lastLogTime = 0

		times.forEach((logTime) => {
			const deltaTime = (logTime - lastLogTime)

			// Check if two logs are too far apart or if we need to create a new session
			if (deltaTime > oneHour || sessionStops[logTime]) {
				// Start a new session with the minimal time (5 min)
				computedTimeData.lastSessionTimestamp = logTime
				computedTimeData.times.push(fiveMinutes)

				// Update sum
				computedTimeData.sum += fiveMinutes
			} else {
				// Increment current session time
				computedTimeData.times[computedTimeData.times.length - 1] += deltaTime

				// Update sum
				computedTimeData.sum += deltaTime
			}

			// Update max
			const sessionTime = computedTimeData.times[computedTimeData.times.length - 1]

			if (sessionTime > computedTimeData.max) {
				computedTimeData.max = sessionTime
			}

			lastLogTime = logTime
		})

		// Compute average time
		if (computedTimeData.times.length > 0) {
			computedTimeData.average = (computedTimeData.sum / computedTimeData.times.length)
		}

		return computedTimeData
	}
}
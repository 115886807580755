import gql from 'graphql-tag'

const GC_GET_ASSET_BY_ID = gql`
query GetAssetByID($id: uuid!) {
  media(where: {id: {_eq: $id}}) {
    id
    title
    name: identifier
    path
    thumbnail_path
    mediaType: type {
      name
    }
    created: contentLogs(where: {type: {slug: {_eq: "content_create"}}}) {
      user {
        first_name
        name: last_name
      }
      created_at
    }
    updated: contentLogs(order_by: {created_at: desc}) {
      user {
        first_name
        name: last_name
      }
      created_at
    }
    modules {
      module {
        id
        identifier
        title
        course_id
      }
    }
    sequences {
      sequence {
        id
        identifier
        title
        module {
          course_id
        }
      }
    }
    nodes {
      scenario {
        id
        identifier
        title
      }
    }
    activities {
      activity {
        id
        identifier
        title
        type {
          slug
        }
      }
    }
    questions {
      activity {
        id
        identifier
        title
        type {
          slug
        }
      }
    }
    answers {
      question {
        activity {
          id
          identifier
          title
          type {
            slug
          }
        }
      }
    }
  }
}
`

const GC_GET_ASSET_LIST = gql`
query GetMediaList {
    media(order_by: {identifier: asc}) {
      id
      name: identifier
      path
      title
      description
      thumbnail_path
      type {
        name
        id
        slug
      }
      activities_aggregate {
        aggregate {
          count
        }
      }
      questions_aggregate {
        aggregate {
          count
        }
      }
      answers_aggregate {
        aggregate {
          count
        }
      }
      modules_aggregate {
        aggregate {
          count
        }
      }
      sequences_aggregate {
        aggregate {
          count
        }
      }
      nodes_aggregate {
        aggregate {
          count
        }
      }
    }
  }
`

const GC_GET_TYPE_LIST = gql`
query GetTypes {
  media_type(order_by: {name: asc}) {
    name
    id
    slug
  }
}
`

const GC_INSERT_MEDIA_ONE = gql`
mutation InsertMedia($name:String!, $path:String!, $tPath:String!, $type:uuid!) {
  insert_media_one(object: {identifier: $name, path: $path, thumbnail_path: $tPath, media_type_id:$type, title: ""}) {
    id
    path
    name: identifier
  }
}`

const GC_UPDATE_MEDIA = gql`
mutation UpdateMedia($id: uuid!, $name: String, $title:String, $description:String, $type:uuid, $path: String, $thumbnail_path: String) {
  update_media(where: {id: {_eq: $id}}, _set: {description: $description, identifier: $name, title: $title, media_type_id: $type, thumbnail_path: $thumbnail_path, path:$path}) {
    affected_rows
  }
}
`

const GC_DELETE_MEDIA_ONE = gql`
mutation DeleteMedia($id:uuid!) {
  delete_media(where: {id: {_eq: $id}}) {
    affected_rows
  }
}

`

export {
    GC_GET_ASSET_LIST,
    GC_GET_TYPE_LIST,
    GC_UPDATE_MEDIA,
    GC_INSERT_MEDIA_ONE,
    GC_DELETE_MEDIA_ONE,
    GC_GET_ASSET_BY_ID
}
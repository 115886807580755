<template>
  <div class="text-black">
    <!-- Buttons -->
    <div v-if="editor" class="mb-2">
      <button
        class="h-8 w-8 border hover:bg-gray-lighter"
        :class="{ 'bg-black text-white': editor.isActive('bold') }"
        @click="editor.chain().focus().toggleBold().run()"
      >
        <b-icon-type-bold />
      </button>

      <button
        class="h-8 w-8 border hover:bg-gray-lighter"
        :class="{ 'bg-black text-white': editor.isActive('italic') }"
        @click="editor.chain().focus().toggleItalic().run()"
      >
        <b-icon-type-italic />
      </button>

      <button
        class="h-8 w-8 border hover:bg-gray-lighter"
        :class="{ 'bg-black text-white': editor.isActive('link') }"
        @click="toggleLink()"
      >
        <b-icon-link />
      </button>

      <button
        class="h-8 w-8 border hover:bg-gray-lighter ml-2"
        :class="{ 'bg-black text-white': editor.isActive({ textAlign: 'left' }) }"
        @click="editor.chain().focus().setTextAlign('left').run()"
      >
        <b-icon-text-left />
      </button>

      <button
        class="h-8 w-8 border hover:bg-gray-lighter"
        :class="{ 'bg-black text-white': editor.isActive({ textAlign: 'center' }) }"
        @click="editor.chain().focus().setTextAlign('center').run()"
      >
        <b-icon-text-center />
      </button>

      <button
        class="h-8 w-8 border hover:bg-gray-lighter"
        :class="{ 'bg-black text-white': editor.isActive({ textAlign: 'right' }) }"
        @click="editor.chain().focus().setTextAlign('right').run()"
      >
        <b-icon-text-right />
      </button>

      <button
        class="h-8 w-8 border hover:bg-gray-lighter"
        :class="{ 'bg-black text-white': editor.isActive({ textAlign: 'justify' }) }"
        @click="editor.chain().focus().setTextAlign('justify').run()"
      >
        <b-icon-justify />
      </button>

      <button
        class="h-8 w-8 border hover:bg-gray-lighter ml-2"
        :class="{ 'bg-black text-white': editor.isActive('bulletList') }"
        @click="editor.chain().focus().toggleBulletList().run()"
      >
        <b-icon-list-ul />
      </button>

      <button
        class="h-8 w-8 border hover:bg-gray-lighter"
        :class="{ 'bg-black text-white': editor.isActive('orderedList') }"
        @click="editor.chain().focus().toggleOrderedList().run()"
      >
        <b-icon-list-ol />
      </button>
    </div>

    <editor-content class="twn-input" :editor="editor" />
  </div>
</template>

<script>
  import { Editor, EditorContent } from '@tiptap/vue-2'
  
  // Base
  import Document from '@tiptap/extension-document'
  import Paragraph from '@tiptap/extension-paragraph'
  import Text from '@tiptap/extension-text'

  // Extra
  import History from '@tiptap/extension-history'
  import Bold from '@tiptap/extension-bold'
  import Italic from '@tiptap/extension-italic'
  import Link from '@tiptap/extension-link'
  import TextAlign from '@tiptap/extension-text-align'
  import ListItem from '@tiptap/extension-list-item'
  import BulletList from '@tiptap/extension-bullet-list'
  import OrderedList from '@tiptap/extension-ordered-list'

  
  import { Markdown } from 'tiptap-markdown'

  
  import { defaultMarkdownSerializer } from "prosemirror-markdown"

  function renderTextAlign(node) {
    if (!node.childNodes.length) {
      return
    }

    node.childNodes.forEach((childNode) => {
      if (childNode.innerHTML && childNode.innerHTML.substring(0, 3) === ':::') {
        // Encapsulate text in a P, if needed, to return valid html
        if (childNode.tagName !== 'P') {
          childNode.innerHTML = `<p>${childNode.innerHTML}</p>`
          childNode = childNode.childNodes[0]
        }

        switch (childNode.textContent.substring(3, 4)) {
          case '|':
            childNode.style.textAlign = 'center'
          break

          case '-':
            childNode.style.textAlign = 'justify'
          break

          case '>':
            childNode.style.textAlign = 'right'
          break
        }

        // Find first text node and remove text align marker
        for (let i = 0; i < childNode.childNodes.length; i += 1) {
          if (childNode.childNodes[i].nodeName === '#text') {
            childNode.childNodes[i].textContent = childNode.childNodes[i].textContent.replaceAll(/:::[|\->] /g, '')
            break
          }
        }
      }

      renderTextAlign(childNode)
    })
  }

  // Create a custom paragraph extension to properly serialize empty <p></p> in editor content and parse <br> from markdown-it
  const customParagraphExtension = Paragraph.extend({
    addStorage() {
      return {
        markdown: {
          serialize: (state, node, parent, index) => {
            // Add new line with '\' for empty paragraph
            if (!node.textContent && !node.childCount) {
              state.write('\\\n')
              return
            }

            if (node.attrs.textAlign) {
              let alignModifier = null

              switch (node.attrs.textAlign) {
                case 'center':
                  alignModifier = '|'
                  break

                case 'justify':
                  alignModifier = '-'
                  break

                case 'right':
                  alignModifier = '>'
                  break
              }

              if (alignModifier !== null) {
                state.write(':::' + alignModifier + ' ')// + state.out
              }
            }

            // Default parser if the paragraph is not empty
            defaultMarkdownSerializer.nodes.paragraph(state, node, parent, index)
          },
          parse: {
            updateDOM(element) {
              // Replace markdown-it <br> by real empty paragraph (<p></p>)
              element.innerHTML = element.innerHTML.replaceAll('<br>', '</p><p>')

              // Replace escaped empty paragraph <p>\</p> by real empty paragraph (<p></p>)
              element.innerHTML = element.innerHTML.replaceAll('<p>\\</p>', '<p></p>')

              renderTextAlign(element)
            },
          },
        }
      }
    }
  })

  export default {
    components: {
      EditorContent,
    },
    props: {
      value: {
        type: String,
        default: '',
      },
    },
    data() {
      return {
        editor: null,
      }
    },
    watch: {
      value(value) {
        const isSame = this.editor.storage.markdown.getMarkdown() === value

        if (isSame) {
          return
        }

        this.editor.commands.setContent(value, false)
      },
    },
    mounted() {
      this.editor = new Editor({
        content: this.value,
        extensions: [
          History,
          Document,
          customParagraphExtension,
          Text,
          Bold,
          Italic,
          Link.configure({
            openOnClick: false,
          }),
          TextAlign.configure({
            types: ['heading', 'paragraph'],
          }),
          ListItem,
          BulletList,
          OrderedList,
          Markdown.configure({
            html: true,
          }),
        ],
        onUpdate: () => {
          this.$emit('input', this.editor.storage.markdown.getMarkdown())
        },
      })
    },
    beforeDestroy() {
      this.editor.destroy()
    },
    methods: {
      toggleLink() {
        const previousUrl = this.editor.getAttributes('link').href
        const url = window.prompt('URL', previousUrl)

        // cancelled
        if (url === null) {
          return
        }

        // empty
        if (url === '') {
          this.editor
            .chain()
            .focus()
            .extendMarkRange('link')
            .unsetLink()
            .run()

          return
        }

        // update link
        this.editor
          .chain()
          .focus()
          .extendMarkRange('link')
          .setLink({ href: url })
          .run()
      }
    },
  }
</script>
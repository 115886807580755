<template>
	<BlockSlideFormText
		:block="block"
		:class="{
			'epi-title-center': titleAlign === 'center',
			'epi-title-right': titleAlign === 'right',
		}"
		@update="$emit('update', $event)"
	>
		<template slot="before">
			<!-- Types -->
			<p class="mb-1">Type d'exergue</p>
			<div class="mb-4">
				<label>
					<input type="radio" value="epigraph_default" v-model="type">
					Neutre
				</label>
				<label class="ml-6">
					<input type="radio" value="epigraph_info" v-model="type">
					Information
				</label>
				<label class="ml-6">
					<input type="radio" value="epigraph_warning" v-model="type">
					Avertissement
				</label>
			</div>
		</template>

		<template slot="inline-after-title">
			<a
				title="Aligner à gauche"
				class="inline-block px-1 border hover:bg-gray-lighter ml-1 mb-1"
				:class="{ 'bg-black text-white': !titleAlign }"
				@click="titleAlign = null"
			>
				<b-icon-text-left />
			</a>

			<a
				title="Centrer"
				class="inline-block px-1 border hover:bg-gray-lighter"
				:class="{ 'bg-black text-white': titleAlign === 'center' }"
				@click="titleAlign = 'center'"
			>
				<b-icon-text-center />
			</a>

			<a
				title="Aligner à droite"
				class="inline-block px-1 border hover:bg-gray-lighter"
				:class="{ 'bg-black text-white': titleAlign === 'right' }"
				@click="titleAlign = 'right'"
			>
				<b-icon-text-right />
			</a>
		</template>

		<template slot="after">
			<!-- Button title -->
			<label class="mt-4">
				Text du bouton d'action
				<input
					type="text"
					class="twn-input w-full"
					v-model="text"
				>
			</label>

			<!-- Button link -->
			<label class="mt-4">
				Lien du bouton d'action
				<input
					type="url"
					class="twn-input w-full"
					v-model="link"
				>
			</label>
		</template>
	</BlockSlideFormText>
</template>

<script>
	import { mapState } from 'vuex'

	import BlockSlideFormText from './BlockSlideFormText'

	export default {
		name: 'BlockSlideFormEpigraph',
		components: {
			BlockSlideFormText,
		},
		props: {
			block: {
				type: Object,
				required: true,
			},
		},
		computed: {
			...mapState({
				metaTypes: state => {
					return state.Utils.metaTypeList.reduce((dict, meta) => {
						dict[meta.slug] = meta
						return dict
					}, {})
				}
			}),
			type: {
				get() {
					return this.block.slide_block_type_slug
				},
				set(type) {
					this.$emit('update', {
						slide_block_type_slug: type,
					})
				},
			},
			titleAlign: {
				get() {
					return this.getMetaValue('title_align')
				},
				set(value) {
					this.setMetaValue('title_align', value)
				},
			},
			text: {
				get() {
					return this.getMetaValue('button_text')
				},
				set(value) {
					this.setMetaValue('button_text', value)
				},
			},
			link: {
				get() {
					return this.getMetaValue('button_link')
				},
				set(value) {
					this.setMetaValue('button_link', value)
				},
			},
		},
		methods: {
			getMeta(slug) {
				if (!this.metaTypes[slug])
					return { meta: { value: '~ Meta value error ~' }, index: -1 }

				for (var i = 0; i < this.block.metas.length; i++) {
					if (this.block.metas[i].meta_type_id == this.metaTypes[slug].id) {
						return { meta: this.block.metas[i], index: i }
					}
				}
				
				return { meta: null, index: -1 }
			},
			getMetaValue(slug, defaultValue = null) {
				const { meta } = this.getMeta(slug)

				return ((meta && meta.value) || defaultValue)
			},
			setMetaValue(slug, value) {
				// Copy metas
				let metas = [...this.block.metas]

				// Try to find existing meta with this slug
				let { meta, index } = this.getMeta(slug)

				// Update, add or remove meta value
				if (meta) {
					if (value != null) {
						meta.value = value
					} else {
						metas.splice(index, 1)
					}
				} else if (value) {
					metas.push({
						value,
						meta_type_id: this.metaTypes[slug].id
					})
				}

				this.$emit('update', {
					metas
				})
			},
		},
	}
</script>

<style lang="scss">
.epi-title-center .block-title {
	text-align: center;
}

.epi-title-right .block-title {
	text-align: right;
}
</style>
import { GC_GET_PRODUCT_LIST } from '@/graphql/gescof-product'
import apollo from '@/apolloClient'

export default {
    namespaced: true,
    state: {
        list: [],
    },
    mutations: {
        SET_LIST(state, products) {
            state.list = (products || []);
        }
    },
    actions: {
        async getList({ commit }) {
            const response = await apollo.query({ query: GC_GET_PRODUCT_LIST })

            commit('SET_LIST', response.data.gescof_product)
        },
    }
}